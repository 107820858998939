<template>
	<section class="content">
		<table class="table table-bordered table-hover w-100" ref="tableuser">
			<thead>
				<tr>
					<th>Label</th>
					<th>Nama</th>
					<th>No. Telp</th>
					<th>Provinsi</th>
					<th>Kota</th>
					<th>Kecamatan</th>
					<th>Alamat Lengkap</th>
				</tr>
			</thead>
			<tbody>
			</tbody>
		</table>
		<div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form role="form" @submit="submitForm">
						<div class="modal-header">
							<h5 class="modal-title">{{ formTitle }}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body redesign">
							<p v-if="errors.length" class="alert alert-danger">
								<b>Please correct the following error(s):</b>
								<ul>
									<li v-for="error in errors">{{ error }}</li>
								</ul>
							</p>
							<div class="row">
								<div class="col-md-6 form-group">
									<label class="control-label">Label</label>
									<input
										id="sender_name"
										class="form-control"
										v-model="formSender.sender_name"
										type="text"
										required="required"
										name="sender_name"
										placeholder="Cth: Toko"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Nama Pengirim</label>
									<input type="hidden" name="row_id" value="" />
									<input
										id="pic_name"
										class="form-control"
										v-model="formSender.pic_name"
										type="text"
										required="required"
										name="pic_name"
										placeholder="Cth: Rajib Wijaya"
									/>
									<small>Bisa disamakan dengan nama pengirim</small>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">No. Telepon</label>
									<input
										id="pic_tel"
										pattern="[0-9]{7,}"
										formnovalidate
										class="form-control"
										v-model="formSender.pic_tel"
										type="text"
										required="required"
										name="pic_tel"
										placeholder="Cth: 085XXXXXXXXX"
									/>
								</div>
								<div class="col-md-6 form-group">
									<label class="control-label">Kecamatan atau Kota</label>
									<v-select
										label="name"
										:filterable="false"
										:options="addrOptions"
										v-model="formSender.addr_id"
										:reduce="(opt) => opt.id"
										@search="onSearch"
										@input="setSelected"
									>
										<template slot="no-options"> Cari Kecamatan atau Kota </template>
										<template slot="option" slot-scope="option">
											<div class="d-center">
												{{ option.lokasi }}
											</div>
										</template>
										<template slot="selected-option" slot-scope="option">
											<div class="selected d-center">
												{{ option.lokasi }}
											</div>
										</template>
									</v-select>
								</div>
								<div class="col-md-12 form-group">
									<label class="control-label">Alamat Lengkap</label>
									<textarea
										id="addr_detail"
										name="addr_detail"
										class="form-control"
										v-model="formSender.addr_detail"
										required="required"
										placeholder="Tulis Alamat Lengkap"
									></textarea>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">Kode POS</label>
									<input
										id="kodepos"
										name="kodepos"
										class="form-control"
										v-model="formSender.kodepos"
										type="number"
										minlength="5"
										required="required"
									/>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label id="labelCourierBranch" class="control-label">
										CABANG KURIR (KHUSUS JNE)
									</label>
									<v-select
										label="name"
										:options="courierBranchOption"
										v-model="formSender.code"
										:reduce="(opt) => opt.code"
									>
									</v-select>
								</div>
								<div class="col-md-6 form-group" v-if="false">
									<label class="control-label">ALAMAT JNE</label>
									<v-select
										label="addrName"
										:options="addrJne"
										v-model="formSender.addr_jne"
										:reduce="(opt) => opt.kode"
									>
									</v-select>
								</div>
								<div class="col-md-12">
									<div class="form-control h-100">
										<div class="icheck-material-orange w-100">
											<input type="checkbox" id="utama" v-model="formSender.is_default" @change="formSender.addr_default = formSender.is_default ? 1 : 0" />
											<label for="utama" class="form-check-label w-100">Alamat Utama</label>
										</div>
									</div>
								</div>
								<div class="col-12 mt-3"></div>
								<div class="col">
									<button type="reset" class="btn btn-block btn-outline-warning font-weight-bold py-3" data-dismiss="modal" :disabled="loading">Kembali</button>
								</div>
								<div class="col">
									<button type="submit" class="btn btn-block btn-warning text-light font-weight-bold py-3" :disabled="loading">Simpan Alamat
										<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
											aria-hidden="true"></span></button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "icheck-material";
export default {
	name: "Seller",
	data() {
		return {
			errors: [],
			method: "",
			roles: "",
			formTitle: "Tambah Shipper",
			addrOptions: [],
			formSender: {
				id: 0,
				addr_default: 0,
				is_default: false,
				sender_name: '',
				pic_name: '',
				pic_tel: '',
				kodepos: '',
				addr_jne: 0,
				branch: 0,
				addr_detail: '',
			},
			loading: false,
			customSwal: {},
		};
	},
	computed: {},
	components: {
		vSelect,
	},
	created: function () {
		this.roles = this.$route.meta.roles;
	},
	methods: {
		onSearch(keyword, loading) {
			if (keyword.length > 3) {
				loading(true);
				var vm = this;
				authFetch(`/order/wilayah?q=${escape(keyword)}`).then((res) => {
					res.json().then((json) => (vm.addrOptions = json.items));
					loading(false);
				});
			}
		},
		setSelected(value) {
			this.formSender.kodepos = value;
			var selectedAddress = this.addrOptions.filter((el) => {
				return el.id == value;
			});
			if(selectedAddress.length>0)
			{
				this.formSender.kodepos = selectedAddress[0].kodepos;
				this.formSender.addr_jne = selectedAddress[0].kode_jne;
				this.formSender.branch = selectedAddress[0].kode_cabang;
			}
		},
		submitForm: function (ev) {
			this.loading = true;
			const e = this.$refs;
			var data = Object.keys(this.formSender)
			.map(
				(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.formSender[key])
			)
			.join("&");
			var urlSubmit = "/order/address";
			if (this.method == "PUT") urlSubmit = "/order/address/" + this.formSender.id;

			authFetch(urlSubmit, {
				method: this.method,
				body: data,
			})
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				this.loading = false;
				this.errors = [];
				if (!js.success) {
					for (var key in js.details) {
						if (js.details.hasOwnProperty(key)) {
						this.errors.push(js.details[key]);
						}
					}
				return;
				}
				this.table.api().ajax.reload();
				$(e.formDialog).modal("hide");
			});
			ev.preventDefault();
		},
		deleteForm: function (data) {
			if (data.addr_default == 1) {
				this.customSwal.fire({
					title: 'Tidak bisa menghapus alamat utama',
					html: 'Hilangkan tag alamat utama di menu edit.',
					showConfirmButton: false,
					showCancelButton: true,
					cancelButtonText: 'Kembali',
				});
			} else {
				this.customSwal.fire({
					title: 'Ingin menghapus alamat ' + data.sender_name + '?',
					showDenyButton: true,
					confirmButtonText: 'Hapus',
					denyButtonText: 'Batal',
				}).then((result) => {
					if (result.isConfirmed) {
						authFetch("/order/address/" + data.id, {
							method: "DELETE",
							body: "id=" + data.id,
						})
						.then((res) => {
							return res.json();
						})
						.then((js) => {
							this.table.api().ajax.reload();
						});
					}
				});
			}
		},
	},
	mounted() {
		this.customSwal = Swal.mixin({
			customClass: {
				popup: 'w-fit',
				title: 'h4 tex-dark',
				actions: 'd-flex justify-content-between flex-row-reverse',
				confirmButton: 'btn btn-primary w-49 font-weight-bold py-3',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold py-3',
				cancelButton: 'btn btn-primary btn-block font-weight-bold py-3',
			},
			buttonsStyling: false
		});
		const e = this.$refs;
		var self = this;
		this.table = createTable(e.tableuser, {
			title: "Data Alamat Pengirim",
			roles: this.$route.params.roles,
			ajax: "/order/address_table",
			frame: true,
			lengthChange: false,
			responsive: true,
			scrollX: true,
			filter: true,
			processing: true,
			language: {
			processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
			columns: [
				{ data: "sender_name", render: function(data, type, row, meta) {
					return data + (row.addr_default == 1 ? ' <span class="badge badge-success">UTAMA</span>' : '');
				} },
				{ data: "pic_name" },
				{ data: "pic_tel" },
				{ data: "propinsi" },
				{ data: "kota" },
				{ data: "kecamatan" },
				{ data: "addr_detail" },
			],
			filterBy: [0, 1, 2, 3, 4, 5, 6],
			rowCallback: function (row, data) {},
			buttonClick: (evt) => {
				if (evt.role == "create") {
					self.formSender = {};
					self.method = "POST";
					self.errors = [];
					self.formTitle = "Tambah Alamat";
					$(e.formDialog).modal("show");
				} else if (evt.role == "update" && evt.data) {
					self.addrOptions = [{ 
						id: evt.data.addr_id, 
						kodepos: evt.data.kodepos,
						addr_jne: evt.data.kode_jne,
						branch: evt.data.kode_cabang,
						lokasi: evt.data.kota + " \/ " + evt.data.kecamatan }];
					self.formSender = evt.data;
					self.formSender.is_default = evt.data.addr_default == 1;
					self.method = "PUT";
					self.errors = [];
					self.formTitle = "Edit Alamat";
					$(e.formDialog).modal("show");
				} else if (evt.role == "delete" && evt.data) {
					this.deleteForm(evt.data);
				}
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
		var head_item = this.table.columns(1).header();
		$(head_item).html('new header');
	},
};
</script>